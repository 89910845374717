export const GET_TOKENS_DATA = 'GET_TOKENS_DATA'
export const GET_TOKENS_PRICES = 'GET_TOKENS_PRICES'
export const TOGGLE_DARK_THEME = 'TOGGLE_DARK_THEME'
export const TOOGLE_POLICY_POPUP = 'TOOGLE_POLICY_POPUP'
export const SCROLL = 'SCROLL'
export const CONNECT = 'CONNECT'
export const SET_WALLET = 'SET_WALLET'
export const GET_USER_DATA = 'GET_USER_DATA'
export const SET_USER_DATA = 'SET_USER_DATA'
export const GET_CHART_DATA = 'GET_CHART_DATA'
export const TOOGLE_CHART_INTERVAL = 'TOOGLE_CHART_INTERVAL'
export const GET_GENERAL_STATS = 'GET_GENERAL_STATS'
export const TOOGLE_CHART_TYPE = 'TOOGLE_CHART_TYPE'
export const TOGGLE_RPC_NODE_POPUP = 'TOGGLE_RPC_NODE_POPUP'
export const SHOW_TOASTER = 'SHOW_TOASTER'
export const HIDE_TOASTER = 'HIDE_TOASTER'
export const SET_RPC_NODES = 'SET_RPC_NODES'
export const SELECT_NEW_RPC_APP_NODE = 'SET_NEW_RPC_APP_NODE'
export const DISCONNECT = 'DISCONNECT'
export const GET_PERSONAL_STATS = 'GET_PERSONAL_STATS'
export const SET_TEZOS_TOOLKIT = 'SET_TEZOS_TOOLKIT'
export const GET_TEZOS_HISTORY_PRICES = 'SET_TEZOS_HISTORY_PRICES'
export const GET_BAKERY_DELEGATES = 'GET_BAKERY_DELEGATES'
